/* eslint-disable import/first */
import React from "react"
import { graphql, Link } from "gatsby"
import { LocaleContext } from "../components/layout"
import SEO from "../components/seo"

const AboutUsPage = ({ data: { prismicAboutUsPage } }) => {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if (lang.i18n[lang.locale] != undefined) {
    i18n = lang.i18n[lang.locale]
  }

  return (
    <>
      <SEO title="About Us" keywords={[`navigo`, `garmin`]} />
      {prismicAboutUsPage && (
        <div className="container about-us-page-container">
          <div className="row">
            <div className="col-xs-12">
              <h1>{prismicAboutUsPage.data.page_title.text}</h1>
            </div>
          </div>
          <div className="row">
            <div
              className="col-xs-12"
              dangerouslySetInnerHTML={{
                __html: prismicAboutUsPage.data.about_us_text.html,
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AboutUsPage

export const pageQuery = graphql`
  query AboutUsPageQuery($locale: String!) {
    prismicAboutUsPage(lang: { eq: $locale }) {
      data {
        page_title {
          text
        }
        about_us_text {
          html
        }
      }
    }
  }
`
